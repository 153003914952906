
export default {
  props: {
    communityData: {
      type: Object,
      default: () => {},
    },
    userPosition: {
      type: String,
      default: "",
    },
    withJoin: {
      type: Boolean,
      default: true,
    },
  },
};


export default {
  props: ["loading", "data"],
  data() {
    return {};
  },
  methods: {
    urlHandler() {
      return `${process.env.BASE_COMMUNITY}/mbls`;
    },
  },
};
